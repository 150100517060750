import * as React from 'react';
import ReactPlayer from 'react-player';

//  https://www.npmjs.com/package/react-player

export const ResponsivePlayer: React.FC<{ url: string }> = ({url}) => <div style={{
    position: 'relative',
    paddingTop: '56.25%',
}}>
    <ReactPlayer
        controls={true}
        url={url}
        width='100%'
        height='100%'
        style={{
            position: 'absolute',
            top: 0,
            left: 0,
        }}
    />
</div>;