import parse, { attributesToProps, domToReact } from 'html-react-parser';
import * as React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ExternalLink from './ExternalLink';
import InternalLink from './InternalLink';
import { ResponsivePlayer } from './ResponsivePlayer';

const externalLinkPrefix = 'https://cdn.caymanchem.com/cdn/insert';

const internalLinkPrefix = 'https://www.caymanchem.com';
const internalLinkPrefixLength = internalLinkPrefix.length;

const isInternal = (href: string) => href.startsWith(internalLinkPrefix) && !href.startsWith(externalLinkPrefix);

/** We have legacy links that target the deprecated static.caymanchem.com endpoint */
const staticCmsLinkPrefix = 'https://static.caymanchem.com/cms/caymanchem/';
const isStaticCms = (href: string) => href.startsWith(staticCmsLinkPrefix);

interface HTMLProps {
    markup: string;
}

const vimeo = new RegExp('player.vimeo.com/video/(?<id>\\d+)', 'i');

export default class HTML extends React.PureComponent<HTMLProps> {
    render() {
        return this.props.markup ? parse(this.props.markup, {
            replace: (node: any) => {
                //  short circuit since most of our nodes will be text
                const attribs = node.attribs;
                if (node.type === 'text' || typeof attribs === 'undefined' || attribs.name) {
                    return false;
                }

                //  Internal links
                if (node.name === 'a' && attribs.href && !attribs.href.startsWith('#')) {
                    const children = domToReact(node.children);

                    const href = attribs.href;

                    if (isInternal(href)) {
                        const to = href.substring(internalLinkPrefixLength);
                        return <InternalLink className={'text-underline'} to={to}>{children}</InternalLink>;
                    } else {
                        if (isStaticCms(href)) {
                            const cdn2 = href.replace(staticCmsLinkPrefix, 'https://cdn2.caymanchem.com/cdn/cms/caymanchem/');
                            return <ExternalLink href={cdn2}>{children}</ExternalLink>;
                        }
                        return <ExternalLink href={href}>{children}</ExternalLink>;
                    }
                }

                //  Images
                if (node.name === 'img') {
                    //  Point embedded CMS image URLs at CDN2
                    const src: string = node.attribs.src.startsWith('/cms/caymanchem') ? `https://cdn2.caymanchem.com/cdn${node.attribs.src}` : node.attribs.src;

                    return <LazyLoadImage {...attributesToProps(node.attribs)} src={src}/>;
                }

                //  Vimeo videos
                if (node.name === 'iframe' && vimeo.test(attribs.src)) {
                    return <ResponsivePlayer url={`https://vimeo.com/${vimeo.exec(attribs.src)?.groups?.id}`}/>;
                }

                return false;
            }
        }) : <></>;
    }
}