import { useEffect, useState } from 'react';
import HTML from '../../common/HTML';

const empty = '';

export const MOTD: React.FC = () => {
    const [motd, setMotd] = useState<string>(empty);

    useEffect(() => {
        fetch(new URL(process.env.REACT_APP_MOTD as string)).
        then(response => response.ok ? response.text() : Promise.resolve(empty)).
        then(html => setMotd(html)).
        catch(reason => empty )
        ;
    }, []);

    return <HTML markup={motd}/>;
};