import './style/scss/app.scss';

import loadable from '@loadable/component';
import { syncHistoryWithStore, SynchronizedHistory } from '@superwf/mobx-react-router';
import { createBrowserHistory } from 'history';
import { configure } from 'mobx';
import preval from 'preval.macro';
import * as React from 'react';
import { withCookies } from 'react-cookie';
import { Helmet } from 'react-helmet';
import { RouterProps } from 'react-router';
import { Redirect, Route, RouteComponentProps, Router, Switch } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import { MOTD } from './component/cch/common/MOTD';
import { GlobalBuyersGuideViewParams } from './component/cch/page/GlobalBuyersGuideView';
import { BASENAME, WindowWithTinker } from './constants';
import { EmptyProps } from './constants/EmptyProps';
import { routerStore } from './storeSingleton/routerStore';
import MetrayaMoveFAQ from './component/cch/page/MatreyaMoveFAQ';

const About = loadable(() => import('./component/cch/page/About'));
const AccountView = loadable(() => import('./component/cch/page/account/AccountView'));
const AnalysisToolsView = loadable(() => import('./component/cch/page/analysisTools/AnalysisToolsView'));
const ApisView = loadable(() => import('./component/cch/page/apis/ApisView'));
const BatchView = loadable(() => import('./component/cch/page/BatchView'));
const BlankView = loadable(() => import('./component/cch/page/BlankView'));
const BrowserView = loadable(() => import('./component/cch/page/BrowserView'));
const CalendarView = loadable(() => import('./component/cch/page/CalendarView'));
const CancerView = loadable(() => import('./component/cch/page/cancer/CancerView'));
const CannabinoidResearchToolsView = loadable(() => import('./component/cch/landing/pages/CannabinoidResearchToolsView'));
const CannabisAndHempAnalyticalStandardsView = loadable(() => import('./component/cch/landing/pages/CannabisAndHempAnalyticalStandardsView'));
const CartDump = loadable(() => import('./component/cch/cart/CartDump'));
const CategoriesView = loadable(() => import('./component/cch/page/CategoriesView'));
const CdcFasKitDocuments = loadable(() => import('./component/cch/forensics/CdcFasKitDocuments'));
const CheckoutView = loadable(() => import('./component/cch/page/CheckoutView'));
const ChemAssistantView = loadable(() => import('./component/cch/page/ChemAssistantView'));
const ConferenceScheduleView = loadable(() => import('./component/cch/page/ConferenceScheduleView'));
const ContactView = loadable(() => import('./component/cch/page/contact/ContactView'));
const CoronavirusResearchToolsView = loadable(() => import('./component/cch/landing/pages/CoronavirusResearchToolsView'));
const CopyrightView = loadable(() => import('./component/cch/page/CopyrightView'));
const EquipmentView = loadable(() => import('./component/cch/page/EquipmentView'));
const FAQItem = loadable(() => import('./component/cch/page/FAQItem'));
const FAQView = loadable(() => import('./component/cch/page/FAQView'));
const FlipbookView = loadable(() => import('./component/cch/page/forensics/FlipbookView'));
const FluorescentSpectraViewerView = loadable(() => import ('./component/cch/page/chemAsisstant/FluorescentSpectraViewerView'));
const ForensicsView = loadable(() => import('./component/cch/page/forensics/ForensicLanding'));
const GlobalBuyersGuideView = loadable(() => import('./component/cch/page/GlobalBuyersGuideView'));
const HMRPView = loadable(() => import('./component/cch/page/HMRPView'));
const HRView = loadable(() => import('./component/cch/page/HRView'));
const IntellectualProperty = loadable(() => import('./component/cch/page/IntellectualProperty'));
const KinasesView = loadable(() => import('./component/cch/landing/pages/KinasesView'));
const LipidNanoparticlesView = loadable(() => import('./component/cch/landing/pages/LipidNanoparticlesView'));
const LiteratureItemView = loadable(() => import('./component/cch/page/LiteratureItemView'));
const LiteratureView = loadable(() => import('./component/cch/page/LiteratureView')) as any;
const LipidsView = loadable(() => import('./component/cch/page/lipids/LipidsView'));
const MolarityCalculatorView = loadable(() => import ('./component/cch/page/chemAsisstant/MolarityCalculatorView'));
const MolarMassAndMassPercentCompositionCalculator = loadable(() => import('./component/cch/page/chemAsisstant/MolarMassAndMassPercentCompositionCalculator'));
const NewProductSuggestion = loadable(() => import('./component/cch/page/NewProductSuggestion'));
const NewsItemView = loadable(() => import('./component/cch/page/NewsItemView'));
const NewsView = loadable(() => import('./component/cch/page/NewsView')) as any;
const OrderTermsView = loadable(() => import('./component/cch/page/OrderTermsView'));
const PasswordReset = loadable(() => import('./component/cch/page/PasswordReset'));
const PrecellysView = loadable(() => import('./component/cch/page/PrecellysView'));
const PrivacyView = loadable(() => import('./component/cch/page/PrivacyView'));
const ProductView = loadable(() => import('./component/cch/page/ProductView'));
const ProductPartnersView = loadable(() => import('./component/cch/page/ProductPartnersView'));
const PsychedelicsView = loadable(() => import('./component/cch/landing/pages/PsychedelicsView'));
const RegisterView = loadable(() => import('./component/cch/page/RegisterView'));
const RunTheCaymanComparisonView = loadable(() => import('./component/cch/landing/pages/RunTheCaymanComparisonView'));
const SearchFullView = loadable(() => import('./component/cch/page/SearchFullView'));
const ServicesView = loadable(() => import('./component/cch/page/services/ServicesView'));
const SitemapView = loadable(() => import('./component/cch/page/Sitemap'));
const Staff = loadable(() => import('./component/cch/page/Staff'));
const CaymanNavbar = loadable(() => import('./component/cch/navigation/CaymanNavbar'));
const CaymanNavbarSelection = loadable(() => import('./component/cch/navigation/CaymanNavbarSelection'));
const StructureDefinitionsView = loadable(() => import('./component/cch/page/StructureDefinitionsView'));
const StyleView = loadable(() => import('./component/cch/page/StyleView'));
const SupplierView = loadable(() => import('./component/cch/page/SupplierView'));
const TestView = loadable(() => import('./component/cch/page/TestView'));
const FontView = loadable(() => import('./component/cch/page/FontView'));
const ComponentShowcase = loadable(() => import('./component/cch/page/ComponentShowcase'));
const VersionView = loadable(() => import('./component/cch/page/VersionView'));
const Footer = loadable(() => import('./component/cch/navigation/Footer'));
const Home = loadable(() => import('./component/cch/page/home/Home'));
const Header = loadable(() => import('./component/cch/navigation/Header'));
const ErrorView = loadable(() => import('./component/cch/ErrorView'));
const TechnicalSupportView = loadable(() => import('./component/cch/support/TechnicalSupport'));
const ResetPassword = loadable(() => import('./component/cch/page/ResetPassword'));
const CookieBanner = loadable(() => import('./component/cch/navigation/CookieBanner'));
configure({enforceActions: 'never'});
const fasKitUri = '/forensics/search/faskit';

declare let window: WindowWithTinker;
// once declared here ^ we can slap whatever we'd like into it
window.app = {
    printRouteChanges: process.env.DEBUG_PRINT_ROUTE_CHANGES || false,
};

class DebugRouter extends React.PureComponent<RouterProps> {
    constructor(props: RouterProps) {
        super(props);
        props.history.listen((location, action) => {
            if (window.app.printRouteChanges) {
                console.log(action, location);
            }
        });
    }

    render() {
        return <Router {...this.props}>{this.props.children}</Router>;
    }
}

function App() {

    const browserHistory = createBrowserHistory({basename: BASENAME});
    const history: SynchronizedHistory = syncHistoryWithStore(browserHistory, routerStore);

    const darkMode: string = 'darkMode';
    return <div
        data-timestamp={preval`module.exports = new Date().toLocaleString();`}
        data-environment={process.env.REACT_APP_ENVIRONMENT}
        style={localStorage.getItem(darkMode) !== null ? {filter: 'invert(100%) hue-rotate(180deg)'} : {}}
    >
        <CookieBanner/>
        <MOTD/>
        <Container
            className={'h-100'}
            fluid={true}
            data-node-env={process.env.NODE_ENV}
        >
            <Helmet>
                <title>Cayman Chemical</title>
                <meta
                    name={'REACT_APP_COMPANY_NAME'}
                    content={process.env.REACT_APP_COMPANY_NAME}
                />
            </Helmet>

            <DebugRouter history={history}>
                <Row>
                    <Col className={'bg-white'}>
                        <Header/>
                        <CaymanNavbar/>
                        <CaymanNavbarSelection
                            largeView={true}
                            className={''}
                        />

                        <Row className={'bg-gray border-top border-gray'}>
                            <Switch>

                                <Redirect
                                    from={'/app/template/Home.vm'}
                                    to={'/home'}
                                />

                                <Route
                                    exact={true}
                                    path="/(|home|index)"
                                    component={Home}
                                />

                                <Route
                                    exact={true}
                                    path="/newproductsuggestion"
                                    component={NewProductSuggestion}
                                />

                                <Route
                                    exact={true}
                                    path="/search"
                                    render={(props: any) =>
                                        <SearchFullView {...props}/>}
                                />

                                <Redirect
                                    from={'/article'}
                                    to={'/news'}
                                />

                                <Route
                                    exact={true}
                                    path="/about"
                                    component={About}
                                />
                                <Route
                                    exact={false}
                                    path="/analysistools"
                                    render={(props: RouteComponentProps) =>
                                        <AnalysisToolsView {...props}/>}
                                />
                                <Route
                                    exact={true}
                                    path="/batch"
                                    component={BatchView}
                                />
                                <Route
                                    exact={true}
                                    path="/blank"
                                    component={BlankView}
                                />
                                <Route
                                    exact={true}
                                    path="/browser"
                                    component={BrowserView}
                                />
                                <Route
                                    exact={false}
                                    path="/apis"
                                    render={(props: any) =>
                                        <ApisView {...props}/>}
                                />
                                <Route
                                    exact={true}
                                    path="/calendar"
                                    component={CalendarView}
                                />

                                <Route
                                    exact={true}
                                    path={'/cannabinoidresearchtools'}
                                    component={CannabinoidResearchToolsView}
                                />

                                <Route
                                    exact={true}
                                    path={'/cannabisandhempanalyticalstandards'}
                                    component={CannabisAndHempAnalyticalStandardsView}
                                />

                                <Redirect
                                    from={'/caymanchallenge'}
                                    to={'/home'}
                                />

                                <Route
                                    exact={true}
                                    path="/productQualifiers/Home"
                                    component={CategoriesView}
                                />
                                <Route
                                    exact={true}
                                    path="/products/categories"
                                    component={CategoriesView}
                                />
                                <Redirect
                                    from={'/Products/kits'}
                                    to={{
                                        pathname: '/search',
                                        search: '?fq=raptas:RAP000008'
                                    }}
                                />
                                <Redirect
                                    from={'/Products/proteins'}
                                    to={{
                                        pathname: '/search',
                                        search: '?fq=raptas:RAP000073'
                                    }}
                                />
                                <Route
                                    exact={true}
                                    path={'/chemassistant/tool/4002'}
                                    component={MolarMassAndMassPercentCompositionCalculator}
                                />
                                <Route
                                    exact={true}
                                    path={'/chemassistant/tool/4003'}
                                    component={MolarityCalculatorView}
                                />
                                <Route
                                    exact={true}
                                    path={'/chemassistant/tool/4004'}
                                    component={FluorescentSpectraViewerView}
                                />
                                <Route
                                    exact={true}
                                    path="/chemassistant"
                                    component={ChemAssistantView}
                                />
                                <Route
                                    exact={false}
                                    path="/checkout"
                                    component={CheckoutView}
                                />
                                <Route
                                    exact={true}
                                    path="/conferenceschedule"
                                    render={(props: any) =>
                                        <ConferenceScheduleView {...props}/>}
                                />
                                <Route
                                    exact={true}
                                    path={'/contact'}
                                    render={() => <Redirect to={'/contact/cc'}/>}
                                />
                                <Route
                                    exact={false}
                                    path="/contact"
                                    render={(props: any) =>
                                        <ContactView {...props}/>}
                                />
                                <Route
                                    exact={true}
                                    path={'/coronavirusresearchtools'}
                                    component={CoronavirusResearchToolsView}
                                />
                                <Route
                                    exact={true}
                                    path="/copyright"
                                    component={CopyrightView}
                                />
                                <Route
                                    exact={true}
                                    path="/equipment"
                                    component={EquipmentView}
                                />
                                <Redirect
                                    from={'/equipmentsampleprep'}
                                    to={'/equipment'}
                                />

                                <Route
                                    exact={true}
                                    path="/faq/:q"
                                    render={(props: any) =>
                                        <FAQItem {...props}/>}
                                />
                                <Route
                                    path="/faq"
                                    render={(props: any) => <FAQView
                                        {...props}
                                        showFacets={true}
                                    />}
                                />
                                <Route
                                    path="/faskitdocuments"
                                    component={CdcFasKitDocuments}
                                />

                                <Route path={'/font'} component={FontView}/>

                                <Route
                                    path="/forensics"
                                    render={(props: RouteComponentProps<EmptyProps>) =>
                                        <ForensicsView {...props}/>}
                                />

                                <Route
                                    exact={true}
                                    path={'/globalbuyersguide'}
                                    render={(props: RouteComponentProps<any>) => <GlobalBuyersGuideView {...props}/>}
                                />
                                <Redirect
                                    exact={true}
                                    path={'/globalbuyersguide/home'}
                                    to={'/globalbuyersguide/us'}
                                />
                                <Redirect
                                    exact={true}
                                    from={'/globalbuyersguide/territory/:territoryId'}
                                    to={'/globalbuyersguide/:territoryId'}
                                />
                                <Route
                                    exact={true}
                                    path="/globalbuyersguide/:territoryId"
                                    render={(props: RouteComponentProps<GlobalBuyersGuideViewParams>) =>
                                        <GlobalBuyersGuideView {...props}/>}
                                />

                                <Route
                                    exact={true}
                                    path="/hmrp/:promotion?"
                                    render={(props: RouteComponentProps<any>) => <HMRPView {...props}/>}
                                />
                                <Route
                                    exact={true}
                                    path="/hr"
                                    component={HRView}
                                />
                                <Route
                                    exact={true}
                                    path="/intellectualproperty"
                                    component={IntellectualProperty}
                                />
                                <Route
                                    exact={true}
                                    path={'/kinases'}
                                    component={KinasesView}
                                />
                                <Redirect
                                    path={'/kitRecommendations'}
                                    to={'products/categories'}
                                />

                                <Route
                                    exact={true}
                                    path={'/lipid-nanoparticles'}
                                    component={LipidNanoparticlesView}
                                />

                                <Redirect
                                    exact={true}
                                    path="/lipids"
                                    to={'/lipids/overview'}
                                />
                                <Route
                                    path="/lipids"
                                    render={(props: RouteComponentProps<EmptyProps>) => <LipidsView {...props}/>}
                                />

                                <Route
                                    exact={true}
                                    path="/literature/:urlName"
                                    render={(props) =>
                                        <LiteratureItemView {...props}/>}
                                />
                                <Route
                                    exact={true}
                                    path="/literature"
                                    render={(props: RouteComponentProps) =>
                                        <LiteratureView
                                            showFacets={true}
                                            {...props}
                                        />}
                                />

                                <Redirect
                                    from={'/maps'}
                                    to={'/contact/cc'}
                                />

                                <Route
                                    exact={true}
                                    path="/news"
                                    render={(props: RouteComponentProps) =>
                                        <NewsView
                                            showFacets={true}
                                            {...props}
                                        />}
                                />
                                <Route
                                    exact={true}
                                    path="/news/:q"
                                    render={(props: any) =>
                                        <NewsItemView {...props}/>}
                                />
                                <Route
                                    exact={true}
                                    path="/orderterms"
                                    component={OrderTermsView}
                                />
                                <Route
                                    exact={true}
                                    path="/productline/supplier/precellys"
                                    component={PrecellysView}
                                />
                                <Route
                                    exact={true}
                                    path="/productline/supplier/:id"
                                    render={(props) =>
                                        <SupplierView {...props}/>}
                                />
                                <Route
                                    exact={true}
                                    path={'/privacy'}
                                    component={PrivacyView}
                                />
                                <Route
                                    exact={true}
                                    path="/productpartners"
                                    component={ProductPartnersView}
                                />
                                <Route
                                    path="/account"
                                    render={(props: any) =>
                                        <AccountView {...props}/>}
                                />

                                <Redirect
                                    exact={false}
                                    from={'/product/(9003237|9003286|9003380|9003381|9004121|9004329|9004330)'}
                                    to={fasKitUri}
                                />
                                <Redirect
                                    exact={false}
                                    from={'/fas'}
                                    to={fasKitUri}
                                />
                                <Redirect
                                    exact={false}
                                    from={'/faskit'}
                                    to={fasKitUri}
                                />

                                <Route
                                    exact={true}
                                    path="/product/:id/:name?"
                                    render={(props: any) =>
                                        <ProductView {...props}/>}
                                />
                                <Route
                                    exact={true}
                                    path="/flipbook/:id?"
                                    render={(props: any) =>
                                        <FlipbookView {...props}/>}
                                />
                                <Route
                                    exact={true}
                                    path="/login"
                                    render={(props: any) =>
                                        <AccountView {...props}/>}
                                />
                                <Route
                                    exact={true}
                                    path={'/psychedelics'}
                                    component={PsychedelicsView}
                                />

                                <Redirect
                                    exact={true}
                                    path="/services"
                                    to={'/services/overview'}
                                />
                                <Route
                                    exact={false}
                                    path="/services"
                                    render={(props: any) =>
                                        <ServicesView {...props}/>}
                                />
                                <Route
                                    exact={true}
                                    path="/sitemap"
                                    component={SitemapView}
                                />
                                <Route
                                    exact={true}
                                    path="/staff"
                                    component={Staff}
                                />
                                <Route
                                    exact={true}
                                    path="/structuredefinitions"
                                    component={StructureDefinitionsView}
                                />

                                <Redirect
                                    from={'/register'}
                                    to={'/registration'}
                                />

                                <Route
                                    exact={true}
                                    path="/registration"
                                    component={RegisterView}
                                />

                                <Route
                                    exact={true}
                                    path={'/runthecaymancomparison'}
                                    component={RunTheCaymanComparisonView}
                                />

                                <Redirect
                                    from={'/reverseTransfectionReporterAssays'}
                                    to={{pathname: '/search', search: '?fq=raptas:RAP000288'}}
                                />

                                <Route
                                    exact={true}
                                    path={'/style'}
                                    component={StyleView}
                                />

                                <Redirect
                                    from={'/technicalservices'}
                                    to={'/technicalsupport'}
                                />
                                <Route
                                    exact={true}
                                    path="/technicalsupport"
                                    component={TechnicalSupportView}
                                />
                                <Route
                                    exact={true}
                                    path="/resetPassword"
                                    render={(props: RouteComponentProps<any>) => <ResetPassword {...props}/>}
                                />
                                <Route
                                    exact={true}
                                    path="/PasswordReset"
                                    component={PasswordReset}
                                />
                                <Route
                                    exact={true}
                                    path="/test"
                                    component={TestView}
                                />
                                <Route
                                    exact={true}
                                    path="/componentShowcase"
                                    component={ComponentShowcase}
                                />
                                <Route
                                    exact={true}
                                    path="/version"
                                    component={VersionView}
                                />

                                <Redirect
                                    exact={true}
                                    path="/cancer"
                                    to={'/cancer/overview'}/>

                                <Route
                                    exact={false}
                                    path={'/cancer'}
                                    component={CancerView}/>

                                <Route
                                    exact={true}
                                    path={'/matreya'}
                                    component={MetrayaMoveFAQ}/>

                                <Redirect
                                    exact={true}
                                    path={'/pcrassays'}
                                    to={'/productline/supplier/pcrassays'}/>

                                <Route render={(props: any) => <ErrorView {...props}/>}/>

                            </Switch>
                        </Row>
                        <Footer/>
                    </Col>

                </Row>

            </DebugRouter>
            {process.env.REACT_APP_DUMP_CART === 'true' && <CartDump/>}
        </Container>
    </div>;

}

export default withCookies(App);